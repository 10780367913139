<template>
  <a-form :form="form" @submit="onSubmit" >
    <a-form-item label="Наименование товара">
      <a-input v-decorator="[
          'Name',
          { initialValue: formData.Name,
          rules: [{ required: true, message: 'Пожалуйста заполните Наименование товара' }] },
        ]" />
    </a-form-item>
    <a-form-item v-if="formData.Category" label="Категория">
      <a-select
        @change="categorySelect"
        :filterOption="filterCategory"
        :showSearch="true"
        v-decorator="[
          'CategoryId',
          { initialValue: formData.Category.Id, rules: [{ required: true,
          message: 'Пожалуйста введите Категорию' }] }, ]"
        :default-value="formData.Category.Name">
        <a-select-option v-for="category in categories" :key="category.Name" :value="category.Id">
          {{ category.Name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="Аттрибуты">
        <a-button-group>
          <a-select style="width: 200px" @change="selectAttribute">
            <a-select-option v-for="(attribute, index) in attributes"
                             :key="attribute.Id"
                             :value="index">
              {{ attribute.Name }}
            </a-select-option>
          </a-select>
          <a-button type="default" v-on:click="addAttribute">Добавить</a-button>
        </a-button-group>
      <div style="width: 400px">
        <a-input-search  v-for="(attribute, index) in formData.Attributes" :key="index"
                 :addon-before="attribute.Name"
                         v-on:keydown.enter.prevent="removeAttribute"
                         :default-value="attribute.Value"
                         @change="fillAttributeValue($event, index)"
                         @search="removeAttribute(index)">
          <a-button slot="enterButton" style="cursor: pointer">
            Удалить
          </a-button>
        </a-input-search>
      </div>
    </a-form-item>
    <a-form-item v-if="formData.Measurement" label="Ед.Измерение">
      <a-select
        @change="categoryMeasurement"
        v-decorator="[
          'MeasurementId',
          { initialValue: formData.Measurement.Id, rules: [{ required: true,
          message: 'Пожалуйста введите Единицу Измерение' }] }, ]"
        :default-value="formData.Measurement.Name">
        <a-select-option v-for="measurement in measurements"
                         :key="measurement.Name" :value="measurement.Id">
          {{ measurement.Name }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="Цена товара">
      <a-input-number
        v-decorator="[
          'Price',
          { initialValue: formData.Price,
          rules: [{ required: true, message: 'Пожалуйста введите цену' }] },
        ]"
        :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
        :step="0.01"
      />
    </a-form-item>

    <a-form-item label="Описание">
      <a-input v-decorator="[
          'Description',
          { initialValue: formData.Description,
          rules: [{ required: false, message: 'Пожалуйста описание товара' }] },
        ]" type="textarea" />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" :loading="loading" @click="onSubmit">
        Сохранить
      </a-button>
      <a-button style="margin-left: 10px;">
        <router-link :to="{ name: 'products' }">Отмена</router-link>
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_login' });
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: false,
      categories: [],
      measurements: [],
      formData: [],
      measurementId: 0,
      attributes: [],
      selectedAttribute: 0,
    };
  },
  computed: {
    defaultMeasurement() {
      return [this.formData.Measurement.Id];
    },
    barcode() {
      return this.formData.Attributes[0];
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    categoryMeasurement(value) {
      this.form.setFieldsValue({
        MeasurementId: value,
      });
    },
    categorySelect(value) {
      this.form.setFieldsValue({
        CategoryId: value,
      });
    },
    selectAttribute(index) {
      this.selectedAttribute = index;
    },
    addAttribute() {
      const { Name, Id } = this.attributes[this.selectedAttribute];
      this.formData.Attributes.push({ Name, Id, Value: '' });
    },
    removeAttribute(index) {
      this.formData.Attributes.splice(index, 1);
    },
    fillAttributeValue(event, index) {
      this.formData.Attributes[index].Value = event.target.value;
    },
    onSubmit() {
      this.form.validateFields((err, values) => {
        const formValues = values;
        formValues.Attributes = this.formData.Attributes.map((item) => {
          if (item.Value !== '') {
            const { Id, Value } = item;
            return { AttributeId: Id, Value };
          }
          return {};
        }).filter((item) => Object.keys(item).length);

        if (!err) {
          this.loading = true;
          this.$api.saveProduct(this.$router.currentRoute.params, formValues, () => {
            this.$router.push({ name: 'products' });
            this.loading = false;
          }, () => {
            this.loading = false;
          });
        }
      });
    },
    fetch() {
      this.$api.getCategoriesList(null, ({ data }) => {
        this.categories = data.data;
      });
      this.$api.getProduct(this.$router.currentRoute.params, ({ data }) => {
        this.formData = data.data;
      });
      this.$api.getMeasurementList(false, ({ data }) => {
        this.measurements = data.data;
      });
      this.$api.getAttributesList(false, ({ data }) => {
        this.attributes = data.data;
      });
    },
    filterCategory(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
