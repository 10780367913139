var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-form-item',{attrs:{"label":"Наименование товара"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'Name',
        { initialValue: _vm.formData.Name,
        rules: [{ required: true, message: 'Пожалуйста заполните Наименование товара' }] } ]),expression:"[\n        'Name',\n        { initialValue: formData.Name,\n        rules: [{ required: true, message: 'Пожалуйста заполните Наименование товара' }] },\n      ]"}]})],1),(_vm.formData.Category)?_c('a-form-item',{attrs:{"label":"Категория"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'CategoryId',
        { initialValue: _vm.formData.Category.Id, rules: [{ required: true,
        message: 'Пожалуйста введите Категорию' }] } ]),expression:"[\n        'CategoryId',\n        { initialValue: formData.Category.Id, rules: [{ required: true,\n        message: 'Пожалуйста введите Категорию' }] }, ]"}],attrs:{"filterOption":_vm.filterCategory,"showSearch":true,"default-value":_vm.formData.Category.Name},on:{"change":_vm.categorySelect}},_vm._l((_vm.categories),function(category){return _c('a-select-option',{key:category.Name,attrs:{"value":category.Id}},[_vm._v(" "+_vm._s(category.Name)+" ")])}),1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"Аттрибуты"}},[_c('a-button-group',[_c('a-select',{staticStyle:{"width":"200px"},on:{"change":_vm.selectAttribute}},_vm._l((_vm.attributes),function(attribute,index){return _c('a-select-option',{key:attribute.Id,attrs:{"value":index}},[_vm._v(" "+_vm._s(attribute.Name)+" ")])}),1),_c('a-button',{attrs:{"type":"default"},on:{"click":_vm.addAttribute}},[_vm._v("Добавить")])],1),_c('div',{staticStyle:{"width":"400px"}},_vm._l((_vm.formData.Attributes),function(attribute,index){return _c('a-input-search',{key:index,attrs:{"addon-before":attribute.Name,"default-value":attribute.Value},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.removeAttribute.apply(null, arguments)},"change":function($event){return _vm.fillAttributeValue($event, index)},"search":function($event){return _vm.removeAttribute(index)}}},[_c('a-button',{staticStyle:{"cursor":"pointer"},attrs:{"slot":"enterButton"},slot:"enterButton"},[_vm._v(" Удалить ")])],1)}),1)],1),(_vm.formData.Measurement)?_c('a-form-item',{attrs:{"label":"Ед.Измерение"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'MeasurementId',
        { initialValue: _vm.formData.Measurement.Id, rules: [{ required: true,
        message: 'Пожалуйста введите Единицу Измерение' }] } ]),expression:"[\n        'MeasurementId',\n        { initialValue: formData.Measurement.Id, rules: [{ required: true,\n        message: 'Пожалуйста введите Единицу Измерение' }] }, ]"}],attrs:{"default-value":_vm.formData.Measurement.Name},on:{"change":_vm.categoryMeasurement}},_vm._l((_vm.measurements),function(measurement){return _c('a-select-option',{key:measurement.Name,attrs:{"value":measurement.Id}},[_vm._v(" "+_vm._s(measurement.Name)+" ")])}),1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"Цена товара"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'Price',
        { initialValue: _vm.formData.Price,
        rules: [{ required: true, message: 'Пожалуйста введите цену' }] } ]),expression:"[\n        'Price',\n        { initialValue: formData.Price,\n        rules: [{ required: true, message: 'Пожалуйста введите цену' }] },\n      ]"}],attrs:{"formatter":function (value) { return ("$ " + value).replace(/\B(?=(\d{3})+(?!\d))/g, ','); },"parser":function (value) { return value.replace(/\$\s?|(,*)/g, ''); },"step":0.01}})],1),_c('a-form-item',{attrs:{"label":"Описание"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'Description',
        { initialValue: _vm.formData.Description,
        rules: [{ required: false, message: 'Пожалуйста описание товара' }] } ]),expression:"[\n        'Description',\n        { initialValue: formData.Description,\n        rules: [{ required: false, message: 'Пожалуйста описание товара' }] },\n      ]"}],attrs:{"type":"textarea"}})],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(" Сохранить ")]),_c('a-button',{staticStyle:{"margin-left":"10px"}},[_c('router-link',{attrs:{"to":{ name: 'products' }}},[_vm._v("Отмена")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }